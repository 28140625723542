import React from 'react';
import { useLocation } from 'react-router-dom';

const Home: React.FC = () => {
  const location = useLocation();

  React.useEffect(() => {
    const domain = window.location.href;

    // console.log(JSON.stringify(location));

    // console.log({ domain });

    const urlToRedirect = domain
      .replace('/#!', '')
      .replace('localhost:3000', 'profitfy.com')
      .replace('profitfy.me', 'profitfy.com');

    // console.log({ urlToRedirect });

    window.location.href = urlToRedirect;
  }, [location]);

  return <div />;
};

export default Home;
